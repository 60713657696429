<template>
    <BT-Blade-Items
        bladeName="customer-locations"
        :bladesData="bladesData"
        canSearchLocal
        :canSelect="false"
        canShowInactive
        customURL="/Locations/Locations"
        :getParams="() => { return { includeDetails: false } }"
        :headers="[
            { text: 'Company Name', value: 'buyer.companyName', title: 1, csv: true, searchable: true },
            { text: 'Lat', value: 'lat', subtitle: 1, csv: true },
            { text: 'Long', value: 'lng', subtitle: 2, csv: true }]"
        hideActions
        navigation="customer-locations"
        :onPullSuccessAsync="pullLocations"
        title="Customer Locations"
        useServerPagination>
        <template slot="settings">
            
        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Customer-Locations-Blade',
    data: function() {
        return {
            
        }
    },
    props: {
        bladesData: {
            type: Object,
            default: null
        }
    },
    methods: {
        pullLocations(list) {
            var rList = [];

            list.forEach(c => {
                c.possibleLocations.forEach(p => {
                    rList.push({
                        buyer: c.buyer,
                        lat: p.lat,
                        lng: p.lng
                    });
                })
            })

            return rList;
        }
    }
}
</script>